import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { DangerouslySetInnerHtml } from 'layout';
import { VisualType, VisualVariant } from 'common/Button';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';
import { BANNER_BACKGROUND_COLOR } from 'utils/constants';

import BannerImage from './BannerImage';
import { TITLE_LENGTH } from './constants';
import { BannerMode, BannerProps } from './models.d';

import './Banner.scss';
import './ShadowBanner.scss';

const Banner: FC<BannerProps> = ({
  description,
  title,
  alt,
  mode = BannerMode.article,
  background = BANNER_BACKGROUND_COLOR.pink,
  image,
  logo,
  logoAlt,
  mainButton,
  mainButtonAriaLabel,
  backgroundImage,
  mobileBackgroundImage,
  backgroundImageAlt,
  titleStyle,
  descriptionStyle,
  bannerStyle,
}) => {
  const { isMobile } = useScreenRecognition();
  const bannerClassNames = classNames(`banner banner--${background}`, {
    [`banner--${bannerStyle}`]: bannerStyle?.length,
  });
  const titleClassNames = classNames(`banner__title banner__title--${mode}`, {
    'colored-title': titleStyle?.length,
    [`banner__title--${titleStyle}`]: titleStyle?.length,
    'small-title': title.length > TITLE_LENGTH,
  });
  const descriptionClassNames = classNames(`banner__description banner__description--${mode}`, {
    'colored-description': descriptionStyle?.length,
    [`banner__description--${descriptionStyle}`]: descriptionStyle?.length,
  });

  return (
    <Row data-testid={`${mode}-banner`} className={bannerClassNames}>
      {isMobile && mode === BannerMode.product ? (
        <div data-testid={`${mode}-mobile-banner`}>
          <div className={`banner__left-section banner__left-section--${mode}`}>
            <Col className="banner__information">
              <h1 className={titleClassNames}>{title}</h1>
            </Col>
          </div>
          <Col>
            <DangerouslySetInnerHtml
              html={description}
              className={descriptionClassNames}
              data-testid="banner-description"
            />
            <div className={`banner__right-section banner__right-section--${mode}`}>
              <div className={`banner__image banner__image--${mode}`}>
                <BannerImage image={image} alt={alt} />
              </div>
            </div>
          </Col>
          {mobileBackgroundImage?.imagePicker ? (
            <div className="banner__background banner__background-mobile">
              <Image imageData={mobileBackgroundImage.imagePicker} alt={alt} objectFit="cover" />
            </div>
          ) : null}
        </div>
      ) : (
        <>
          <div className={`banner__left-section banner__left-section--${mode}`}>
            <Col className="banner__information">
              <h1 className={titleClassNames}>{title}</h1>
              <DangerouslySetInnerHtml
                html={description}
                className={descriptionClassNames}
                data-testid="banner-description"
              />
              {mainButton?.length ? (
                <div className="banner__link">
                  <LinkButton
                    text={mainButton[0].name}
                    type={VisualType.Discover}
                    url={mainButton[0].url}
                    ariaLabel={mainButtonAriaLabel}
                    variant={
                      background === BANNER_BACKGROUND_COLOR.pink
                        ? VisualVariant.Light
                        : VisualVariant.Dark
                    }
                  />
                </div>
              ) : null}
            </Col>
          </div>
          <div className={`banner__right-section banner__right-section--${mode}`}>
            {!isMobile && logo ? (
              <div className="banner__right-section-logo">
                <Image imageData={logo} alt={logoAlt || ''} />
              </div>
            ) : null}
            <div className={`banner__image banner__image--${mode}`}>
              <BannerImage image={image} alt={alt} />
            </div>
          </div>
          {backgroundImage ? (
            <div className="banner__background">
              <BannerImage image={backgroundImage} alt={backgroundImageAlt} />
            </div>
          ) : null}
        </>
      )}
    </Row>
  );
};

export default Banner;
