import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import { VisualType, VisualVariant } from 'common/Button';
import LinkButton from 'components/LinkButton';

import { PageDescriptionProps } from './models';

import './PageDescription.scss';
import './ShadowPageDescription.scss';

export const PageDescription = ({
  description,
  backgroundColor = 'white',
  ctaLink,
  ctaAriaLabel,
  isCustomStyleDescription,
}: PageDescriptionProps) => (
  <Row
    data-testid="page-description"
    className={classNames(`page-description--${backgroundColor} page-description__wrapper`)}
  >
    <Col lg={{ span: 10, offset: 1 }}>
      <DangerouslySetInnerHtml
        html={description}
        className={classNames('page-description', {
          [`page-description--custom-style list__image-marker`]: isCustomStyleDescription,
        })}
      />
      {ctaLink && ctaAriaLabel ? (
        <LinkButton
          text={ctaLink[0].name}
          type={VisualType.Primary}
          url={ctaLink[0].url}
          ariaLabel={ctaAriaLabel}
          variant={VisualVariant.Dark}
        />
      ) : null}
    </Col>
  </Row>
);

export default PageDescription;
