import { ImageDataLike } from 'common/Image/models.d';
import { BANNER_BACKGROUND_COLOR } from 'utils/constants';

export interface BannerImageInnerProps {
  imageCropper: Component.ResponsiveImage | null;
  imagePicker: ImageDataLike | null;
}

export interface BannerProps {
  description: string;
  title: string;
  alt: string;
  mode?: BannerMode;
  background?: BANNER_BACKGROUND_COLOR;
  image: BannerImageInnerProps;
  logo?: Component.ResponsiveImage;
  logoAlt?: string;
  mainButton?: {
    name: string;
    url: string;
  }[];
  mainButtonAriaLabel?: string;
  backgroundImage?: BannerImageInnerProps;
  mobileBackgroundImage?: BannerImageInnerProps;
  backgroundImageAlt?: string;
  titleStyle?: string;
  descriptionStyle?: string;
  bannerStyle?: string;
}

export interface BannerImageProps {
  image: BannerImageInnerProps;
  alt: string;
}

export enum BannerMode {
  article = 'article',
  product = 'product',
}
